<template>
  <div>
    <b-col cols="12" xl="12" class="mb-2">
      <div class="d-flex">
        <feather-icon icon="AwardIcon" size="19" />
        <h4 class="mb-0 ml-50">團隊資料</h4>
        <b-button variant="primary" id="edit_button" @click="handleEdit">
          修改
        </b-button>
        <b-button id="cancel_button" :to="{ name: 'teams-list' }">
          取消
        </b-button>
      </div>
    </b-col>
    <b-form>
      <!-- Field : 團隊名稱 -->
      <b-col cols="12" xl="6" sm="12">
        <b-form-group label="團隊編號" label-for="id" label-cols-md="4">
          <b-form-input :value="teamData.id" id="id" placeholder="團隊名稱" />
        </b-form-group>
      </b-col>
      <b-col cols="12" xl="6" sm="12">
        <b-form-group label="團隊名稱" label-for="team_name" label-cols-md="4">
          <b-form-input v-model="teamData.team_name" id="team_name" placeholder="團隊名稱" />
        </b-form-group>
      </b-col>

      <!-- Field : 團隊介紹-->
      <b-col cols="12" xl="6" sm="12">
        <b-form-group label="團隊介紹" label-for="team_description" label-cols-md="4">
          <b-form-textarea id="team_description" v-model="teamData.team_description" autofocus trim />
        </b-form-group>
      </b-col>

      <!-- Label: 團隊隊長 --> 

      <b-col cols="12">
        <b-form-group label="團隊隊長" label-cols-md="2">
          <template v-for="(data, index) in teamData.users" >
            <b-badge class="badge-glow" v-if="data.is_teamleader" variant="success" :key="index" >
              {{ data.name_en }}
            </b-badge>
          </template>
        </b-form-group>
      </b-col>

      <!-- Label: 團隊成員 -->
      <b-col cols="12">
        <b-form-group label="團隊成員" label-cols-md="2">
          <el-transfer v-model="selectedUserData" :data="usersData" :titles="['選擇成員', '團隊成員']" @change="handleChange" >
          </el-transfer>
        </b-form-group>
      </b-col>
    </b-form>
  </div>
</template>

<script>


import {
  BBadge,
  BCardText,
  BCard,
  BRow,
  BCol,
  BButton,
  BFormGroup,
  BFormInput,
  BForm,
  BFormTextarea,
} from "bootstrap-vue";

import store from "@/store";
import { ref, onUnmounted, onMounted } from "@vue/composition-api";

export default {
  components: {
    BBadge,
    BCardText,
    BCard,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
    BForm,
    BFormTextarea,
  },
  props: {
    teamData: {
      type: Object,
      required: true,
    },
  },
  methods: {
    handleChange() {
      let data = {
        id: this.teamData.id,
        users: this.selectedUserData
      };
      store
        .dispatch("app-team/syncUser", data)
        .then((response) => {
          this.$swal({
            text: response.data.message,
            icon: "success",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            showClass: {
              popup: "animate__animated animate__bounceIn",
            },
            buttonsStyling: false,
          });
          this.$emit("refetch-data");
        })
        .catch((error) => {
          this.$swal({
            text: JSON.stringify(error.response.data.message),
            icon: "error",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            showClass: {
              popup: "animate__animated animate__bounceIn",
            },
            buttonsStyling: false,
          });
        });
    },
    handleEdit() {
      this.$swal({
        title: "確認儲存?",
        showCancelButton: true,
        confirmButtonText: "儲存",
        cancelButtonText: '取消',
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          store
            .dispatch("app-team/updateTeam", this.teamData)
            .then((response) => {
              this.$swal({
                text: response.data.message,
                icon: "success",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                showClass: {
                  popup: "animate__animated animate__bounceIn",
                },
                buttonsStyling: false,
              });
              this.$emit("refetch-data");
            })
            .catch((error) => {
              this.$swal({
                text: JSON.stringify(error.response.data.message),
                icon: "error",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                showClass: {
                  popup: "animate__animated animate__bounceIn",
                },
                buttonsStyling: false,
              });
            });
        }
      });
    },
  },
  setup(props) {
    const usersData = ref([]);
    const selectedUserData = ref([]);

    const APP_TEAM_STORE_MODULE_NAME = "app-team";

    //Register module
    if (!store.hasModule(APP_TEAM_STORE_MODULE_NAME))
      store.registerModule(APP_TEAM_STORE_MODULE_NAME, teamStoreModule)
    onUnmounted(() => {
      if (store.hasModule(APP_TEAM_STORE_MODULE_NAME))
        store.unregisterModule(APP_TEAM_STORE_MODULE_NAME)
    });

    const refetchData = () => {
      store.dispatch("app-team/fullUserList")
        .then((response) => {

          let teamleader_id = null;

          props.teamData.users && props.teamData.users.length > 0 && props.teamData.users.forEach(user => {
            selectedUserData.value.push(user.pivot && user.pivot.user_id)

            if (user.is_teamleader == 1) {
              teamleader_id = user.pivot.user_id;
            }
          });


          // if (teamleader_id != null) {
            let tempData = response.data.users.map(user => {
              console.log('response user', user);
              if (user.id != teamleader_id) {
                return {
                  key: user.id,
                  label: user.name_en
                };
              } else {
                return {
                  key: user.id,
                  label: user.name_en,
                  disabled: true
                };
              }
            });

            usersData.value = tempData;
          // }

        })
        .catch((error) => {
          console.log(error)
        })
    }

    onMounted(() => {
      refetchData()
    })

    return {
      refetchData,
      usersData,
      selectedUserData,
    }
  }

};
</script>

<style scoped>
.badge {
  padding: 10px !important;
  margin: 10px 20px 10px 0px;
}

#edit_button {
  position: absolute;
  right: 80px;
  padding: 5px 10px;
  top: -3px;
}

#cancel_button {
  position: absolute;
  right: 0px;
  padding: 5px 10px;
  top: -3px;
}

@media only screen and (max-width: 600px) {
  .badge {
    width: 100%;
  }

  #edit_button {
    right: 0px;
  }

  #cancel_button {
    display: none;
  }
}
</style>