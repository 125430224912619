<template>
    <component :is="teamData === undefined ? 'div' : 'b-card'">
    <!-- Alert : No item found -->
    <b-alert variant="danger" :show="teamData === undefined">
      <h4 class="alert-heading">Error fetching team data</h4>
      <div class="alert-body">
        No team found with this team id. Check
        <b-link class="alert-link" :to="{ name: 'teams-list' }">
          Team List
        </b-link>
        for other teams.
      </div>
    </b-alert>
    
    <b-row class="match-height" v-if="teamData">
        <b-col cols="12">
            <team-edit-tab-information :team-data="teamData" @refetch-data="refetchData" class="pt-75" />
        </b-col>
        
    </b-row>
    </component>
</template>

<script>
import { BTab, BTabs, BCard, BAlert, BLink, BRow, BCol } from "bootstrap-vue";
import { ref, onUnmounted } from "@vue/composition-api";
import router from "@/router";
import store from "@/store";
import teamStoreModule from "../teamStoreModule";
import TeamEditTeamMembers from "./TeamEditTeamMembers.vue";
import TeamEditTabInformation from "./TeamEditTabInformation.vue";

export default {
    components: {
        BTab,
        BTabs,
        BCard,
        BAlert,
        BLink,
        BRow,
        BCol,

        TeamEditTabInformation,
        TeamEditTeamMembers,
    },
    setup() {
        const teamData = ref(null)
        const APP_TEAM_STORE_MODULE_NAME = "app-team";

        const team = {
            id : 0
        }

        //Register module
        if (!store.hasModule(APP_TEAM_STORE_MODULE_NAME))
            store.registerModule(APP_TEAM_STORE_MODULE_NAME, teamStoreModule)
        onUnmounted(() => {
            if (store.hasModule(APP_TEAM_STORE_MODULE_NAME))
                store.unregisterModule(APP_TEAM_STORE_MODULE_NAME)
        });

        const refetchData = () => {
            store.dispatch("app-team/fetchTeam", { id: router.currentRoute.params.id })
            .then((response) => {
                teamData.value = response.data.team
            })
            .catch((error) => {
                if(error.response.status === 404) {
                    teamData.value = undefined
                }
            })
        }

        refetchData()

        return {
            teamData,
            refetchData
        }
    }


}

</script>