import axiosIns from "@/libs/axios";

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchTeam(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axiosIns
          .get(`/team/${id}`)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    fetchTeams(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosIns
          .get("/team/list", { params: queryParams })
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    addTeam(ctx, data) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post("/team", data)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    updateTeam(ctx, data) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post("/team", data)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    deleteTeam(ctx, data) {
      return new Promise((resolve, reject) => {
        axiosIns
          .delete("/team", { params: data })
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },

    fullUserList(ctx, data) {
      return new Promise((resolve, reject) => {
        axiosIns
          .get("/user/fullUserList")
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    syncUser(ctx, data) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post("/team/syncUser", data)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
  },
};
